export default [
  {
    title: 'Почетна',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Активација',
    route: 'activation',
    icon: 'CheckIcon',
  },
  {
    title: 'Подршка',
    route: 'instructions',
    icon: 'HelpCircleIcon',
  },
]
  

